export const minABIForTokens = [
  // transfer
  {
    "name": "transfer",
    "type": "function",
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ]
  }
]

export const USDT_TOKEN_ADDRESS = '0x96C47e89768234871c9Daed2cB5953f8643006c8'

export const LION_TOKEN_ADDRESS = '0xeCc80B0575E227a84AB319C198300dA55DB3a30C'

export const CURRENCIES_WEY_UNITS = {
  USDT: 'mwei',
  ETH: 'ether',
  LION: 'ether'
}

export const NETWORK_VERSION = '3210'

export const ETH_CURRENCY_NAMES = {
  ETH: 'ETH',
  LION: 'LION',
  USDT: 'USDT'
}

export const FIAT_CURRENCY_NAMES = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
}

export const FIAT_CURRENCIES = Object.keys(FIAT_CURRENCY_NAMES)

export const ETH_CURRENCIES = Object.keys(ETH_CURRENCY_NAMES)

export const ETH_TOKEN_CURRENCIES = [ETH_CURRENCY_NAMES.LION, ETH_CURRENCY_NAMES.USDT]
