export const CC_PROCESS_TYPES = [
  'MOVE',
  'EXCHANGE_WITH_BOTH_COMMISSION',
  'TRANSFER_SENDER_COMMISSION',
  'TRANSFER_RECEIVER_COMMISSION',
  'DEPOSIT_FUNDS',
  'WITHDRAWAL_FUNDS',
  'REFERRAL_REVENUE',
  'AUTOEXCHANGE',
  'REVENUE_TRANSFER_COMMISSION',
  'REVENUE_TRANSFER_LION_SALE',
  'REFERRAL_REVENUE_TRANSFER'
]

export const KYC_STATUS_NAMES = {
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED'
}

export const KYC_STATUSES = Object.values(KYC_STATUS_NAMES)

export const USER_STATUS_NAMES = {
  ACTIVATED: 'ACTIVATED',
  BLOCKED: 'BLOCKED'
}

export const USER_STATUSES = Object.keys(USER_STATUS_NAMES)

export const ORGANIZATION_TYPE_NAMES = {
  CRYPTOENTER: 'CRYPTOENTER',
  BANK: 'BANK'
}

export const ORGANIZATION_TYPES = Object.keys(ORGANIZATION_TYPE_NAMES)

export const ADMIN_METHODS_MAP = {
  cryptoenterCommission: '/admin/cryptoenter-commission',
  lionCourse: '/admin/lion-exchange-rate',
  createOrganization: '/admin/organization',
  organizationCommission: '',
  processTypeCurrencyLimits: '/admin/currency-constraints',
  gasEthCommission: '/admin/gas-commission',
  blockingUsers: '/admin/user-status',
  autoExchangePairsSettings: '/admin/autoexchange-constraints',
  autoExchangeRateSteps: '',
  autoExchangeCommissionReserve: '',
  systemUserCreate: '/admin/system-user',
  userKycStatus: '/admin/user-kyc-status',
  depositFiat: '/admin/deposit-fiat',
  autoExchangeControl: '/admin/suspend-matching',
  p2pSettings: '/admin/p2p-currency-param',
  p2pClearAll: '/admin/p2p-clear',
  p2pSecurity: '/admin/p2p-security',
  autoExchangeCancelOffers: '/admin/cancel-all-autoexchanges',
  autoExchangeLionCommission: '/admin/ae-request-commission'
}

export const SERVICE_MAN_ROUTE_NAMES = ['cashierView', 'rootCashierView']
