export const FORM_TYPE_NAMES = {
  INTERNAL: 'Internal',
  FIAT: 'Fiat',
  ASSET: 'Asset'
}

export const FORM_TYPES = [
  {
    label: 'Валюты в Cryptoenter',
    value: FORM_TYPE_NAMES.INTERNAL
  },
  {
    label: 'Фиат вне Cryptoenter',
    value: FORM_TYPE_NAMES.FIAT
  },
  {
    label: 'Другое',
    value: FORM_TYPE_NAMES.ASSET
  },
]

export const EXTERNAL_FORM_TYPES = FORM_TYPES.filter(i => i.value !== FORM_TYPE_NAMES.INTERNAL)

export const PAYMENT_TYPE_NAMES = {
  CASH: 'Cash',
  CARD_AMOUNT: 'CardAmount',
  ACCOUNT_AMOUNT: 'AccountAmount'
}

export const PAYMENT_TYPES_LABEL = {
  [PAYMENT_TYPE_NAMES.CASH]: 'Наличные',
  [PAYMENT_TYPE_NAMES.CARD_AMOUNT]: 'Перевод на карту',
  [PAYMENT_TYPE_NAMES.ACCOUNT_AMOUNT]: 'Перевод на счёт',
}

export const PAYMENT_TYPES = Object.values(PAYMENT_TYPE_NAMES).map(paymentType => {
  return {
    label: PAYMENT_TYPES_LABEL[paymentType],
    value: paymentType
  }
})

export const SELECTOR_VALUE_NAMES = {
  BUY: 'BUY',
  SELL: 'SELL'
}

export const SELECTOR_VALUES = [
  {
    value: SELECTOR_VALUE_NAMES.BUY,
    label: 'p2p_selector_buy'
  },
  {
    value: SELECTOR_VALUE_NAMES.SELL,
    label: 'p2p_selector_sell'
  }
]

export const DEAL_STATUS_NAME = {
  WAIT_ACCEPT: 'WaitAccept',
  WAIT_PARTICIPANT_ACCEPT: 'WaitParticipantAccept',
  OPEN: 'Open',
  CLOSED: 'Closed',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
}

export const DEAL_STATUS_LABEL = {
  [DEAL_STATUS_NAME.WAIT_ACCEPT]: 'Ожидает вашего подтверждения',
  [DEAL_STATUS_NAME.WAIT_PARTICIPANT_ACCEPT]: 'Ожидает подтверждения другим пользователем',
  [DEAL_STATUS_NAME.OPEN]: 'Открыта',
  [DEAL_STATUS_NAME.CLOSED]: 'Закрыта',
  [DEAL_STATUS_NAME.ACCEPTED]: 'Подтверждена',
  [DEAL_STATUS_NAME.REJECTED]: 'Отменена',
}
