import { createApp } from 'vue'
import AppComponent from '@/AppMain.vue'
import router from '@/router'
import store from '@/store'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import Notifications from '@kyvg/vue3-notification'
import Session from '@/Plugins/Session/Session'
import CookieWork from '@/Plugins/CookieWork'
import FavIcon from '@/Plugins/favicon'
import eventBus from '@/Plugins/eventBus'
// import VueGtag from 'vue-gtag'
// import VueYandexMetrika from 'vue3-yandex-metrika'
import CopyToClipboard from '@/Plugins/CopyToClipboard'
import GetMessages from '@/i18n/i18n'
import I18nKeys from '@/Plugins/i18n'
import axios from 'axios'
import PrimeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import InlineSvg from 'vue-inline-svg'

import { createI18n } from 'vue-i18n'

import { Buffer } from 'buffer'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import InputMask from 'primevue/inputmask'
import AutoComplete from 'primevue/autocomplete'

import { Capacitor } from '@capacitor/core'

export let app

if (document.querySelector('#app')) {
  window.Buffer = Buffer
  window.isAndroidApp = Capacitor?.getPlatform() === 'android'
// window.isAndroidApp = true

  app = createApp(AppComponent)

  const metaManager = createMetaManager()

  app.use(eventBus)
  app.use(metaManager)
  app.use(metaPlugin)
  app.use(Notifications)
  app.use(Session)
  app.use(FavIcon)
  app.use(CookieWork)
  app.use(CopyToClipboard)
  app.use(I18nKeys)
  app.use(PrimeVue)

  app.use(router)
  app.use(store)

  app.component('InlineSvg', InlineSvg)
  app.component('Dropdown', Dropdown)
  app.component('InputText', InputText)
  app.component('InputMask', InputMask)
  app.component('InputSwitch', InputSwitch)
  app.component('AutoComplete', AutoComplete)

  // app.use(VueGtag, {
  //   config: {
  //     id: 'UA-156684194-1'
  //   }
  // })

  // app.use(VueYandexMetrika, {
  //   id: 66788152,
  //   env: 'production'
  // })

  axios.defaults.timeout = 120_000

  GetMessages()
    .then(messages => {
      return createI18n({
        locale: 'en',
        fallbackLocale: 'en',
        messages
      })
    })
    .then(i18n => {
      app.use(i18n)
      // Обработчик для перезагрузки при ошибке загрузки чанка(например, после обновления)
      window.addEventListener('load', () => {
        localStorage.removeItem('alreadyReloaded')
      })
      window.addEventListener('error', (err) => {
        if (!localStorage.getItem('alreadyReloaded')) {
          localStorage.setItem('alreadyReloaded', 'true')
          window.location.reload()
        }
      })
      return app.mount('#app')
    })
}

