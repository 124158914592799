export default {
   "ru":{
      "startsWith": "Начинается с",
      "contains": "Содержит",
      "notContains": "Не содержит",
      "endsWith": "Заканчивается",
      "equals": "Равно",
      "notEquals": "Не равно",
      "noFilter": "Нет фильтра",
      "filter": "Фильтр",
      "lt": "Меньше чем",
      "lte": "Меньше чем или равно",
      "gt": "Более чем",
      "gte": "Более чем или равно",
      "dateIs": "Дата равна",
      "dateIsNot": "Дата не равна",
      "dateBefore": "Дата до",
      "dateAfter": "Дата после",
      "custom": "Пользовательский",
      "clear": "Очистить",
      "apply": "Принять",
      "matchAll": "Сопоставить все",
      "matchAny": "Совпадение с любым",
      "addRule": "Добавить правило",
      "removeRule": "Удалить правило",
      "accept": "Да",
      "reject": "Нет",
      "choose": "Выбрать",
      "upload": "Загрузить",
      "cancel": "Отмена",
      "completed": "Завершено",
      "pending": "В ожидании",
      "fileSizeTypes": ["Б", "Кб", "Мб", "Гб", "Тб", "Пб", "Эб", "Зб", "Йб"],
      "dayNames": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      "dayNamesShort": ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
      "dayNamesMin": ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      "monthNames": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
      "monthNamesShort": ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
      "chooseYear": "Выбрать год",
      "chooseMonth": "Выбрать месяц",
      "chooseDate": "Выбрать дату",
      "prevDecade": "Предыдущее десятилетие",
      "nextDecade": "Следующее десятилетие",
      "prevYear": "Предыдущий год",
      "nextYear": "Следующий год",
      "prevMonth": "Предыдущий месяц",
      "nextMonth": "Следующий месяц",
      "prevHour": "Предыдущий час",
      "nextHour": "Следующий час",
      "prevMinute": "Предыдущая минута",
      "nextMinute": "Следующая минута",
      "prevSecond": "Предыдущая секунда",
      "nextSecond": "Следующая секунда",
      "am": "до полудня",
      "pm": "после полудня",
      "today": "Сегодня",
      "now": "Сейчас",
      "weekHeader": "Нед.",
      "firstDayOfWeek": 1,
      "showMonthAfterYear": false,
      "dateFormat": "dd.mm.yy",
      "weak": "Простой",
      "medium": "Нормальный",
      "strong": "Хороший",
      "passwordPrompt": "Введите пароль",
      "emptyFilterMessage": "Результатов не найдено",
      "searchMessage": "{0} результатов доступно",
      "selectionMessage": "{0} элементов выбрано",
      "emptySelectionMessage": "Нет выбранного элемента",
      "emptySearchMessage": "Результатов не найдено",
      "emptyMessage": "Нет доступных вариантов",
      "aria": {
        "trueLabel": "Верно",
        "falseLabel": "Неверно",
        "nullLabel": "Не выбран",
        "star": "1 звезда",
        "stars": "{star} звёзд",
        "selectAll": "Выбраны все элементы",
        "unselectAll": "Все элементы не выбраны",
        "close": "Закрыть",
        "previous": "Предыдущий",
        "next": "Следующий",
        "navigation": "Навигация",
        "scrollTop": "Прокрутить в начало",
        "moveTop": "Переместить в начало",
        "moveUp": "Переместить вверх",
        "moveDown": "Переместить вниз",
        "moveBottom": "Переместить в конец",
        "moveToTarget": "Переместить в приёмник",
        "moveToSource": "Переместить в источник",
        "moveAllToTarget": "Переместить всё в приёмник",
        "moveAllToSource": "Переместить всё в источник",
        "pageLabel": "{page}",
        "firstPageLabel": "Первая страница",
        "lastPageLabel": "Последняя страница",
        "nextPageLabel": "Следующая страница",
        "previousPageLabel": "Предыдущая страница",
        "rowsPerPageLabel": "Строк на странице",
        "jumpToPageDropdownLabel": "Перейти к раскрывающемуся списку страниц",
        "jumpToPageInputLabel": "Перейти к вводу страницы",
        "selectRow": "Выбрана строка",
        "unselectRow": "Строка не выбрана",
        "expandRow": "Строка развёрнута",
        "collapseRow": "Строка свёрнута",
        "showFilterMenu": "Показать меню фильтра",
        "hideFilterMenu": "Скрыть меню фильтра",
        "filterOperator": "Оператор фильтра",
        "filterConstraint": "Ограничение фильтра",
        "editRow": "Редактирование строки",
        "saveEdit": "Сохранить правку",
        "cancelEdit": "Отменить правку",
        "listView": "В виде списка",
        "gridView": "В виде сетки",
        "slide": "Слайд",
        "slideNumber": "{slideNumber}",
        "zoomImage": "Увеличить изображение",
        "zoomIn": "Увеличить",
        "zoomOut": "Уменьшить",
        "rotateRight": "Повернуть вправо",
        "rotateLeft": "Повернуть влево"
      }
   },
   "en":{
      "startsWith": "Starts with",
      "contains": "Contains",
      "notContains": "Not contains",
      "endsWith": "Ends with",
      "equals": "Equals",
      "notEquals": "Not equals",
      "noFilter": "No Filter",
      "filter": "Filter",
      "lt": "Less than",
      "lte": "Less than or equal to",
      "gt": "Greater than",
      "gte": "Greater than or equal to",
      "dateIs": "Date is",
      "dateIsNot": "Date is not",
      "dateBefore": "Date is before",
      "dateAfter": "Date is after",
      "custom": "Custom",
      "clear": "Clear",
      "apply": "Apply",
      "matchAll": "Match All",
      "matchAny": "Match Any",
      "addRule": "Add Rule",
      "removeRule": "Remove Rule",
      "accept": "Yes",
      "reject": "No",
      "choose": "Choose",
      "upload": "Upload",
      "cancel": "Cancel",
      "completed": "Completed",
      "pending": "Pending",
      "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      "dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      "monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      "chooseYear": "Choose Year",
      "chooseMonth": "Choose Month",
      "chooseDate": "Choose Date",
      "prevDecade": "Previous Decade",
      "nextDecade": "Next Decade",
      "prevYear": "Previous Year",
      "nextYear": "Next Year",
      "prevMonth": "Previous Month",
      "nextMonth": "Next Month",
      "prevHour": "Previous Hour",
      "nextHour": "Next Hour",
      "prevMinute": "Previous Minute",
      "nextMinute": "Next Minute",
      "prevSecond": "Previous Second",
      "nextSecond": "Next Second",
      "am": "AM",
      "pm": "PM",
      "today": "Today",
      "now": "Now",
      "weekHeader": "Wk",
      "firstDayOfWeek": 0,
      "showMonthAfterYear": false,
      "dateFormat": "mm/dd/yy",
      "weak": "Weak",
      "medium": "Medium",
      "strong": "Strong",
      "passwordPrompt": "Enter a password",
      "emptyFilterMessage": "No results found",
      "searchMessage": "{0} results are available",
      "selectionMessage": "{0} items selected",
      "emptySelectionMessage": "No selected item",
      "emptySearchMessage": "No results found",
      "emptyMessage": "No available options",
      "aria": {
         "trueLabel": "True",
         "falseLabel": "False",
         "nullLabel": "Not Selected",
         "star": "1 star",
         "stars": "{star} stars",
         "selectAll": "All items selected",
         "unselectAll": "All items unselected",
         "close": "Close",
         "previous": "Previous",
         "next": "Next",
         "navigation": "Navigation",
         "scrollTop": "Scroll Top",
         "moveTop": "Move Top",
         "moveUp": "Move Up",
         "moveDown": "Move Down",
         "moveBottom": "Move Bottom",
         "moveToTarget": "Move to Target",
         "moveToSource": "Move to Source",
         "moveAllToTarget": "Move All to Target",
         "moveAllToSource": "Move All to Source",
         "pageLabel": "Page {page}",
         "firstPageLabel": "First Page",
         "lastPageLabel": "Last Page",
         "nextPageLabel": "Next Page",
         "previousPageLabel": "Previous Page",
         "rowsPerPageLabel": "Rows per page",
         "jumpToPageDropdownLabel": "Jump to Page Dropdown",
         "jumpToPageInputLabel": "Jump to Page Input",
         "selectRow": "Row Selected",
         "unselectRow": "Row Unselected",
         "expandRow": "Row Expanded",
         "collapseRow": "Row Collapsed",
         "showFilterMenu": "Show Filter Menu",
         "hideFilterMenu": "Hide Filter Menu",
         "filterOperator": "Filter Operator",
         "filterConstraint": "Filter Constraint",
         "editRow": "Edit Row",
         "saveEdit": "Save Edit",
         "cancelEdit": "Cancel Edit",
         "listView": "List View",
         "gridView": "Grid View",
         "slide": "Slide",
         "slideNumber": "{slideNumber}",
         "zoomImage": "Zoom Image",
         "zoomIn": "Zoom In",
         "zoomOut": "Zoom Out",
         "rotateRight": "Rotate Right",
         "rotateLeft": "Rotate Left"
      }
   }
}
