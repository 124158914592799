import { PROCESS_TYPES, SYSTEM_USERS } from '@/constants/common'

const {
  LOCK_COMMISSION,
  SYSTEM_COMMISSION
} = SYSTEM_USERS

const {
  AUTOEXCHANGE
} = PROCESS_TYPES

export const TRANSACTION_NAME_ALIASES = {
  [`${LOCK_COMMISSION}/${AUTOEXCHANGE}/INPUT`]: 'payment_commission_refund',
  [`${SYSTEM_COMMISSION}/${AUTOEXCHANGE}/OUTPUT`]: 'payment_commission_advance',
}
